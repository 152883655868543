import * as React from 'react'

import { Image } from '../components'
import BadgeAmazon from '../images/market_assets/amazon.svg'
import BadgeApple from '../images/market_assets/apple.svg'
import BadgeAptosAnimated from '../images/market_assets/aptos-s.svg'
import BadgeAptos from '../images/market_assets/aptos.svg'
import BadgeBitcoinAnimated from '../images/market_assets/bitcoin-s.svg'
import BadgeBitcoin from '../images/market_assets/bitcoin.svg'
import BadgeBnbAnimated from '../images/market_assets/bnb-s.svg'
import BadgeBnb from '../images/market_assets/bnb.svg'
import BadgeCardanoAnimated from '../images/market_assets/cardano-s.svg'
import BadgeCardano from '../images/market_assets/cardano.svg'
import BadgeCelestiaAnimated from '../images/market_assets/celestia-s.svg'
import BadgeCelestia from '../images/market_assets/celestia.svg'
import BadgeChainlinkAnimated from '../images/market_assets/chainlink-s.svg'
import BadgeChainlink from '../images/market_assets/chainlink.svg'
import BadgeChilizAnimated from '../images/market_assets/chiliz-s.svg'
import BadgeChiliz from '../images/market_assets/chiliz.svg'
import BadgeEridu from '../images/market_assets/eridu.png'
import BadgeEthereumAnimated from '../images/market_assets/ethereum-s.svg'
import BadgeEthereum from '../images/market_assets/ethereum.svg'
import BadgeHackenAnimated from '../images/market_assets/hacken-s.svg'
import BadgeHacken from '../images/market_assets/hacken.svg'
import BadgeKadenaAnimated from '../images/market_assets/kadena-s.svg'
import BadgeKadena from '../images/market_assets/kadena.svg'
import BadgeKamaleont from '../images/market_assets/kamaleont.png'
import BadgeMicrosoft from '../images/market_assets/microsoft.svg'
import BadgeOasisAnimated from '../images/market_assets/oasis-s.svg'
import BadgeOasis from '../images/market_assets/oasis.svg'
import BadgeOrcaAnimated from '../images/market_assets/orca-s.svg'
import BadgeOrca from '../images/market_assets/orca.svg'
import BadgePolkadotAnimated from '../images/market_assets/polkadot-s.svg'
import BadgePolkadot from '../images/market_assets/polkadot.svg'
import BadgePolygonAnimated from '../images/market_assets/polygon-s.svg'
import BadgePolygon from '../images/market_assets/polygon.svg'
import BadgePolyhedraAnimated from '../images/market_assets/polyhedra-s.svg'
import BadgePolyhedra from '../images/market_assets/polyhedra.svg'
import BadgeRaydiumAnimated from '../images/market_assets/raydium-s.svg'
import BadgeRaydium from '../images/market_assets/raydium.svg'
import BadgeSmilekAnimated from '../images/market_assets/smilek-s.svg'
import BadgeSmilek from '../images/market_assets/smilek.svg'
import BadgeSolanaAnimated from '../images/market_assets/solana-s.svg'
import BadgeSolana from '../images/market_assets/solana.svg'
import BadgeSunAnimated from '../images/market_assets/sun-s.svg'
import BadgeSun from '../images/market_assets/sun.svg'
import BadgeTabooAnimated from '../images/market_assets/taboo-s.svg'
import BadgeTaboo from '../images/market_assets/taboo.svg'
import BadgeTesla from '../images/market_assets/tesla.svg'
import BadgeThetaAnimated from '../images/market_assets/theta-s.svg'
import BadgeTheta from '../images/market_assets/theta.svg'
import BadgeThorchainAnimated from '../images/market_assets/thorchain-s.svg'
import BadgeThorchain from '../images/market_assets/thorchain.svg'
import BadgeThought from '../images/market_assets/thought.png'
import BadgeUf from '../images/market_assets/uf.svg'
import BadgeUniswapAnimated from '../images/market_assets/uniswap-s.svg'
import BadgeUniswap from '../images/market_assets/uniswap.svg'
import BadgeUsdcAnimated from '../images/market_assets/usdc-s.svg'
import BadgeUsdc from '../images/market_assets/usdc.svg'
import BadgeUsdtAnimated from '../images/market_assets/usdt-s.svg'
import BadgeUsdt from '../images/market_assets/usdt.svg'
import BadgeZKSyncAnimated from '../images/market_assets/zksync-s.svg'
import BadgeZKSync from '../images/market_assets/zksync.svg'
import BadgeZurf from '../images/market_assets/zurf.svg'

import type { ImageProps } from '../components'

type AssetBadges = {
  [symbol: string]: undefined | {
    static: string
    animated: string
  }
}

const assetBadges: AssetBadges = {
  'BTC': { static: BadgeBitcoin, animated: BadgeBitcoinAnimated },
  'ETH': { static: BadgeEthereum, animated: BadgeEthereumAnimated },
  'ADA': { static: BadgeCardano, animated: BadgeCardanoAnimated },
  'DOT': { static: BadgePolkadot, animated: BadgePolkadotAnimated },
  'SOL': { static: BadgeSolana, animated: BadgeSolanaAnimated },
  'USDT': { static: BadgeUsdt, animated: BadgeUsdtAnimated },
  'USDC': { static: BadgeUsdc, animated: BadgeUsdcAnimated },
  'BNB': { static: BadgeBnb, animated: BadgeBnbAnimated },
  'LINK': { static: BadgeChainlink, animated: BadgeChainlinkAnimated },
  'RAY': { static: BadgeRaydium, animated: BadgeRaydiumAnimated },
  'APT': { static: BadgeAptos, animated: BadgeAptosAnimated },
  'TIA': { static: BadgeCelestia, animated: BadgeCelestiaAnimated },
  'UNI': { static: BadgeUniswap, animated: BadgeUniswapAnimated },
  'RUNE': { static: BadgeThorchain, animated: BadgeThorchainAnimated },
  'POL': { static: BadgePolygon, animated: BadgePolygonAnimated },
  'THETA': { static: BadgeTheta, animated: BadgeThetaAnimated },
  'HAI': { static: BadgeHacken, animated: BadgeHackenAnimated },
  'ROSE': { static: BadgeOasis, animated: BadgeOasisAnimated },
  'ORCA': { static: BadgeOrca, animated: BadgeOrcaAnimated },
  'ZKJ': { static: BadgePolyhedra, animated: BadgePolyhedraAnimated },
  'TABOO': { static: BadgeTaboo, animated: BadgeTabooAnimated },
  'KDA': { static: BadgeKadena, animated: BadgeKadenaAnimated },
  'CHZ': { static: BadgeChiliz, animated: BadgeChilizAnimated },
  'ZK': { static: BadgeZKSync, animated: BadgeZKSyncAnimated },
  'SMILEK': { static: BadgeSmilek, animated: BadgeSmilekAnimated },
  'SUN': { static: BadgeSun, animated: BadgeSunAnimated },
  'KLT': { static: BadgeKamaleont, animated: BadgeKamaleont },
  'ERIDU': { static: BadgeEridu, animated: BadgeEridu },
  'TOT': { static: BadgeThought, animated: BadgeThought },
  'ZRF': { static: BadgeZurf, animated: BadgeZurf },
  'AAPL': { static: BadgeApple, animated: BadgeApple },
  'MSFT': { static: BadgeMicrosoft, animated: BadgeMicrosoft },
  'TSLA': { static: BadgeTesla, animated: BadgeTesla },
  'AMZN': { static: BadgeAmazon, animated: BadgeAmazon },
  'UF': { static: BadgeUf, animated: BadgeUf },
}

type AssetBadgeProps = Omit<ImageProps, 'src' | 'alt'> & {
  symbol: string
  height?: number
  animated?: boolean
  style?: React.CSSProperties
}

export const AssetBadge = ({
  symbol,
  height,
  animated,
  style,
  ...rest
}: AssetBadgeProps) => {
  const assetBadge = assetBadges[symbol]

  if (typeof assetBadge === 'undefined') {
    return null
  }

  const src = assetBadge[animated ? 'animated' : 'static']

  return animated ? (
    <Image
      src={src}
      alt={`Logo ${symbol}`}
      shift={null}
      loading='lazy'
      fit='contain'
      duration={2000}
      style={style}
      wrapperStyle={height ? {
        height: height,
        paddingLeft: height / 10,
        paddingTop: height / 10,
      } : {}}
      {...rest}
    />
  ) : (
    <img
      src={src}
      alt={`Logo ${symbol}`}
      loading='lazy'
      style={{ ...style, objectFit: 'contain' }}
      height={height}
      {...rest}
    />
  )
}
