import * as React from 'react'

import { Grid } from '@mui/material'

import { AssetDisplay, LoadingAssetDisplay } from './asset_display'

import type { AssetPrices, MarketAsset } from '../../queries'

type AssetsDisplayProps = {
  loading?: boolean
  marketAssets: MarketAsset[]
  assetPrices: AssetPrices[]
}

export const AssetsDisplay = ({
  loading,
  marketAssets,
  assetPrices,
}: AssetsDisplayProps) => (
  <Grid
    item
    container
    rowSpacing={2}
    columnSpacing={3}
  >
    {(loading) ? (
      Array(5).fill(null).map((_, index) => (
        <LoadingAssetDisplay key={index} />
      ))
    ) : (
      marketAssets.map((marketAsset, index) => (
        <AssetDisplay
          key={index}
          marketAsset={marketAsset}
          assetPrices={assetPrices}
        />
      ))
    )}
  </Grid>
)
