import * as React from 'react'

import { CreditCard, Download } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { Link } from '@reach/router'

export type FomoTextProps = {
  text?: React.ReactNode
  symbol?: string
}

export const FomoText = ({
  text,
  symbol,
}: FomoTextProps) => (
  <Stack
    direction='column'
    alignItems='center'
    justifyContent='center'
    height='100%'
    spacing={2}
    px={2}
  >
    <Typography
      variant='h5'
      component='p'
      textAlign='center'
      fontWeight={300}
    >
      {(typeof text !== 'undefined') ? (
        text
      ) : (
        <React.Fragment>
          Tu portafolio {symbol && (
            <React.Fragment>
              de <span style={{ fontWeight: 400 }}>{symbol}</span>
            </React.Fragment>
          )} está vacío.
        </React.Fragment>
      )}
    </Typography>
    <Button
      component={Link}
      to={symbol ? `/app/deposit/${symbol}` : '/app/deposit'}
      startIcon={<Download />}
      variant='contained'
      size='large'
    >
      Depositar
    </Button>
    <Button
      component={Link}
      to='/app/purchase'
      startIcon={<CreditCard />}
      variant='contained'
      size='large'
    >
      Comprar
    </Button>
  </Stack>
)
